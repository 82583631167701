import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import TagsList from '../../templates/TagsList';
import SEO from '../../components/SEO';

const TagsPage = ({
  data: {
    allMdx: { group },
  },
}) => (
  <Layout>
    <TagsList tagGroup={group} />
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
  ]).isRequired,
};

export default TagsPage;

export const Head = ({ data }) => (
  <SEO title={data.site.siteMetadata.title} />
);

export const tagPageQuery = graphql`
  query TagsQuery($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      filter: { fields: { locale: { eq: $locale } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

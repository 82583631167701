import React from 'react';
import { kebabCase } from 'lodash';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from './styled';
import Anchorlink from '../../components/Anchorlink';
import WrapperSection from '../../components/WrapperSection';
import WrapperPage from '../../components/WrapperPage';
import PageImage from '../../components/PageImage';
import TitlePage from '../../components/TitlePage';

const Tags = ({ tagGroup }) => (
  <WrapperPage id="tags">
    <PageImage>
      <StaticImage
        src="../../assets/images/hands.jpg"
        alt="Polityka prywatności"
        style={{ width: '100%', height: '100%', opacity: 0.2 }}
        layout="fullWidth"
        objectPosition="50% 75%"
        quality={85}
        imgStyle={{
          filter: 'grayscale()',
        }}
      />
    </PageImage>
    <WrapperSection>
      <S.Wrapper>
        <S.Container>
          <TitlePage text="Tags" />
          <S.MenuList>
            {tagGroup.map((tag) => (
              <S.ListItem key={tag.fieldValue}>
                <Anchorlink
                  ariaLabel="tag anchor"
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}
                >
                  {tag.fieldValue} ({tag.totalCount})
                </Anchorlink>
              </S.ListItem>
            ))}
          </S.MenuList>
        </S.Container>
      </S.Wrapper>
    </WrapperSection>
  </WrapperPage>
);

export default Tags;

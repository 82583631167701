import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  user-select: none;
`;

export const TagsTitle = styled.h1`
  font-size: 26px;
  margin: 0 0 50px;
  font-weight: 700;
  color: var(--primary-color);
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px;
  ${media.greaterThan('large')`
    max-width:1170px;

  `}
`;

export const MenuList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-right: 15px;
`;
